import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import axios from '@axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { saveAs } from 'file-saver';
export default function useDatasList() {
  // Use toast
  const modulename = "Document";
  const modulenamesub = "document";
  const toast = useToast()

  const refDataListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', thClass: 'nameOfTheClass', label: 'Name', sortable: true },
    { key: 'documenttype', tdClass: 'text-right', thClass: 'text-right', label: 'Data Type', sortable: false },
    { key: 'category', tdClass: 'text-right', thClass: 'text-right', label: 'Category Type', sortable: false },
    { key: 'actions', tdClass: 'text-right', thClass: 'text-right', sortable: false },
  ]
  const perPage = ref(10)
  const totalDatas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDataListTable.value ? refDataListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDatas.value,
    }
  })

  const refetchData = () => {
    if (refDataListTable.value) {
      refDataListTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchDatas = (ctx, callback) => {
    store
      .dispatch('document/fetchDatas', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value ? sortBy.value : 'created_at',
        sortDesc: isSortDirDesc.value ? 'desc' : 'asc',
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const datas = response.data.data
        const total = response.data.meta.total

        callback(datas)
        totalDatas.value = total
      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const editfunction = (data) => {
    var temp = data;
    var appdatax = {
      id: data.id,
      name: temp.name,
      desc: temp.desc,
      category: temp.category,
      customcategory: temp.customcategory,
      customvalue: temp.category == "Others" ? 1 : 0,
    };
    store
      .dispatch("document/editData", appdatax)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            text: modulename + " editted successfully",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          appData.value = undefined;

          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching the Bank",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      });
  };


  const deletefun = (ctx, callback) => {
    store
      .dispatch('document/deleteData', {
        id: ctx,
      })
      .then(response => {
        refetchData()

      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Datas list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const viewfile = (ctx, callback) => {
    axios({

      url: 'https://userfrp.smartfrp.com/api/documents/download/' + ctx.id,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      var blob = new Blob([response.data], {type: ctx.meta.filetypex});
      var blob_url = URL.createObjectURL(blob);
      var blob_iframe = document.querySelector('#blob-src-test');
      blob_iframe.src = blob_url;
    });

  }
  const downloadfun = (ctx, callback) => {
    axios({
      url: 'https://userfrp.smartfrp.com/api/documents/download/' + ctx.id,
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'file.' + ctx.meta.extension);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
      // store
      //   .dispatch('document/downloadData', {
      //     id: ctx.id,
      //   })
      //   .then(response => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "file.png"); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Error fetching Datas list',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //       },
      //     })
      //   })
    }


    // *=======================---*
    // *--------- UI ---------------------------------------*
    // *=======================---*

    const resolveDataRoleVariant = is_active => {
      if (is_active) return 'primary'
      else return 'danger'
    }

    return {
      fetchDatas,
      deletefun, downloadfun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas, editfunction,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      viewfile,
      resolveDataRoleVariant,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  }
