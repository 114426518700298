<template>
  <b-card no-body class="d-print-none invoice-preview-card">
    <b-card-body class="invoice-padding pb-0">
      <h5>{{ $t("fields.files") }}</h5>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzoneqwe"
        :options="dropzoneOptions"
        v-on:vdropzone-sending="addMeta"
        v-on:vdropzone-success="uploadSuccess"
        v-on:vdropzone-file-added="fileadded"
      ></vue-dropzone>
      <table class="mt-2 table mt-xl-0 w-100">
        <tr v-for="(item, index) in documentlist">
          <th class="pb-50 w-50">
            <span class="font-weight-bold">
              {{ item.meta.name || "" }} <br />
              {{ item.meta.readsize || "" }}
            </span>
          </th>
          <th class="pb-50 w-50 text-right">
            <feather-icon
              :id="`document-row-${item.id}-preview-icon`"
              icon="DownloadIcon"
              size="16"
              class="mx-1"
              @click="downloadfun(item)"
            />

            <!-- <feather-icon
              :id="`document-row-${item.id}-preview-icon`"
              icon="EyeIcon"
              @click="viewfile(item)"
              size="16"
              class="mx-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-1

            /> -->
            <feather-icon
              v-on:click="checkdeletefun(item.id, index)"
              :id="`document-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1"
            />
          </th>
        </tr>
      </table>
    </b-card-body>
    <hr class="invoice-spacing" />
    <b-modal id="modal-1" title="Basic Modal" ok-only ok-title="Accept">
    </b-modal>
  </b-card>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BForm,
  BFormTextarea,
  BLink,
  BDropdown,
  BCardBody,
  BFormGroup,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useList from "./useList";
import StoreModule from "../StoreModule";
import Ripple from "vue-ripple-directive";
export default {
  props: ["docuemnts", "modulename", "reference_id", "moduleid"],
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BBadge,
    BFormTextarea,
    BForm,
    BRow,
    BCardBody,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormGroup,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  setup() {
    const GENAPP_APP_STORE_MODULE_NAME = "document";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    var userData = JSON.parse(localStorage.getItem("userData"));
    const categorydata = ref([]);
    const {
      fetchDatas,
      deletefun,
      downloadfun,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      imagedata,
      editfunction,
      statusFilter,
      refetchData,
      resolveDataRoleVariant,
      viewfile,
    } = useList();
    return {
      fetchDatas,
      deletefun,
      downloadfun,
      tableColumns,
      perPage,
      currentPage,
      editfunction,
      totalDatas,
      dataMeta,
      perPageOptions,
      imagedata,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      statusFilter,
      userData,
      refetchData,
      // categorydatafetch,
      categorydata,
      avatarText,
      resolveDataRoleVariant,
      viewfile,
    };
  },
  data: function () {
    return {
      dropzoneOptions: {
        url:
          "https://userfrp.smartfrp.com/api/" +
          this.modulename +
          "/filedocument",
        thumbnailHeight: 120,
        thumbnailWidth: 120,
        acceptedFiles:
          "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
        disablePreviews: true,
        dictDefaultMessage: "<h1>Drop your files here</h1>",
        headers: {
          Authorization: "Bearer " + this.userData.accessToken,
        },
      },
      documentlist: this.docuemnts,
      filedata: {
        id: "",
        name: "",
        desc: "",
        file: "",
      },
    };
  },
  methods: {
    checkdeletefun(val, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletefun(val);
          this.documentlist.splice(index, 1);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: this.modulename + " has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    fileadded: function (file) {
      this.filedata.name = file.name;
    },
    uploadSuccess(file, response) {
        if(!response.hasOwnProperty('success')){
            this.documentlist.push(response);
        }
        else{
            this.$swal({
            icon: "error",
            title: response.title,
            text: response.message,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        }
    },
    updatefiledatamodal() {
      this.editfunction(this.filedata);
    },
    addMeta(file, xhr, formData) {
      var self = this;
      formData.append("customvalue", "0");
      formData.append("name", this.modulename + " " + self.filedata.name);
      formData.append("modulename", this.modulename);
      formData.append("moduleid", this.moduleid);
      formData.append("reference_id", this.reference_id);
    },
  },
};
</script>

<style lang="scss">
@media print {
  d-print-none {
    display: none;
  }
}

.per-page-selector {
  width: 90px;
}

.document-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.nameOfTheClass {
  max-width: 10px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
