import {
  extend,
  localize
} from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  min as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  numeric as rule_numeric,
  max_value as rule_max_value,
  min_value as rule_min_value,

} from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import fr from 'vee-validate/dist/locale/fr.json'
import de from 'vee-validate/dist/locale/de.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorDecimal,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)
export const max = extend('max', rule_max)
export const maxvalue = extend('maxvalue', rule_max_value)
export const minvalue = extend('minvalue', rule_min_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)
export const numeric = extend('numeric', rule_numeric)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
})
export const amount = extend('amount', {
  validate: validatorPositive,
  message: 'Please enter a {_field_} greater than 0',
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})



export const decimaldata = extend("decimaldata", {
  validate: (value, {
    decimals = '*',
    separator = '.'
  } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regexx = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regexx.test(value),
    };
  },
  message: 'The {_field_} field must contain only decimal values'
})



localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
      state: 'State',
      name: 'Name',
      select: "Select",
      customrelations: "Custom relations",
      status: 'Status',
      accountholdername: "Name",
      enddate: "End Date",
      lastName: 'Last Name',
      phonenumber: 'Phone Number',
      gender: 'Gender',
      city: "City",
      languagepreference: 'Language Preference',
      maritalstatus: 'Marital Status',
      educationalqualification: 'Educational Qalification',
      dateofbirth: 'Date of Birth',
      citizen: 'Citizen',
      referedby: 'Refered By',
      allergies: 'Allergies',
      bloodgrouptype: 'Blood group Type',
      relations: ' Relations',
      securitydeposit: "Deposit",
      customerid: "Customer ID",
      typename: "Member Name",
      typedate: "Occasion Date",
      houseaddress: "House",
      typegift: "Gift",
      occupanttype:"Occupant Type",
      commencdate:"Commencement Date",
      tenure:"Tenure",
      typebudge: "Budget",
      oenedby: 'Oened By',
      areainsequarefeet: "Area in Sequare Feet",
      address: "Address",
      pincode: "Pincode",
      propertyid: "Propertyi ID",
      remarks: "Remarks",
      schemename: "Scheme Name",
      make: "Make",
      chasisnumber: "Chassis Number",
      nextvisitdate: "Next Visit Date",
      dossage: "Dosage",
      budget: "Budget",
      gift: "Gift",
      membershipnum: "Membership Number",
      lastcloprice: "Last Closing Price",
      isinname: "ISIN Name",
      weight: "Weight",
      coverage:"Coverage (Individual or Family)",
      tutionfees:"Tution Fees",

      membername: "Member Name",
      ownername: "Name",
      holdername: "Name",
      profession: "Profession",
      company: "Company",
      placeofbirth: "placeof Birth",
      openingbalance: "Opening Balance",
      balance: "Balance",
      asofdate: "As Of Date",
      loangivento: "Amount Given To (Name)",
      outstantamount: "Outstanding Amount",
      customamc: "Custom AMC",
      charges: "charges (Montly)",
      dateofjoin: "Date Of Joining",
      returndate: "Expected Date of Return",
      category: "Category",
      loanavailedfrom: "Loan Availed From",
      loanid: "Loan Account Number",
      emi: "EMI",
      rdnumber: "RD Number",
      dueon: "Due On",
      examcycle: "Exam Cycle",
      organization: "Organization",
      vaccinationtype: "Vaccination Type",
      takenon: "Taken On",
      travelingdate: "Traveling Date",
      partyhallcost: "Party Hall Cost",
      lpgid: "LPG ID",
    itemdescription: "Item Category",

      subject: "Subject",
      lender: "Lender",
      typeofloan:"Type of Loan",
      amountresived:"Amount Received From (Name)",

      markobtain: "Morks Obtained",
      percentageheld: "Percentage Held Less than 100 (%)",

      voteridname: "Name On Voter Id",
      consumernumber: "Consumer Number",
      nameofpancard: "Name Of Pancard",
      nextservicedate: "Next Service Date",
      serialnumber: "Serial Number",
      exclusions: "Exclusions",
      dob: "Date Of Birth",
      aadharcardname:"Aadhaar Card Name",
      familymember: "Family Members",
      aadharnum: "Aadhar Card Number",
      marriagecertificate: "Marriage Certificate",
      number: "Number",
      capacity: "Capacity",
      qualififications: "Qualifications",
      paytype: "Pay Type",
      description: "Description",
      validupto: "Valid Upto",
      fees: "Fees",
      bankpostname: "Bank/Post Office Name",
      childname: "Child Name",
      typeofjewellery: "Type Of Jewellery",
      policeholder: "Policy Holder Name",
      startdate: "Start date",
      rateofintrest: "Rate of Intrest should be enter 1 to 100",
      monthlydebitdate: "Monthly Debit Date should be 1-31 ",
      emidate: "EMI  Date should be between 1-31 of Every Month",
      policyno: "Policy Number (~)",
      premiumamount: "Premium amount should be less than sum assured ",
      renewaldate: "Renewal Date",
      referredby: "Referred By",
      model: "Model",
      date: "EMI Date should be between 1-31 of Every Month",
      premium: "Premium",

      datefoexpiry: "Date Of Eepiry",
      gradeclass: "Class/Grade",
      maximummark: "Maximum Marks",
      honame:"Holder Name",
      yearasfinance:"Year (As per Financial Year)",
      openingbalanceunit: "Opening Balance (units)",

      documentnumber: "Document Number",
      presentvalue: "Precent Value",
      dateofregister: "Date Of Registration",
      visitdate: "Visit Date",
      amount: "Amount",
      occasiondate: "Occasion Date",
      partyarea: "Part Area",
      flouristcost: "Flourist Cost",
      organizationissued: "Organisation Issued",
      nameofcouples: "Name Of Couple",
      mode: "Mode",
      rentmonthly: "Rent(Monthly)",
      nscnumber: "NSC Number",
      advancepaid: "Advance Paid",
      surendervalue:"Surrender Value",
      nextduedate:"Next Due Date",

      doctorname: "Doctor Name",
      hospitalname: "Hospital/Clinic Name",
      prescription: "Prescriptions",
      reports: "Reports",
      departing: "Departing",
      destination: "Destination",
      numpassengers: "Number Of Passagers",
      planningfunftion: "Planning a Function",
      cateringcost: "Catering Cost",
      locations: "Locations",
      passportnumber: "Passport Number",
      imie: "IMEI Number",
      customercode: "Customer Code",
      rankgrade: "Rank",

      passportname: "Name OfPasseport",
      lockernum: "Locker Number",
      crnnumber: "CRN Number",

      nameofcertificate: "Name Of Certificates",
      documentsname: "Documents Name",
      cardnumber: "Card Number",
      pancardnumber: "PAN Card Number",
      nameofcouples: "Name Of Couples",
      billnumber: "Bill Number",
      warrantyupto: "Waranty Upto",
      contractexpirydate: "Contract Expiry Date",
      warrantyexpirydate: "Warannty Expiry Date",
      enginenumber: "Engin Number",
      custommake: "Custom Make",
      yearmodel: "Year (Model)",
      helplinename: "Name",
      modeoftravel: "MOde Of Travel",
      tenthousecost: "Tent House Cost",
      dlnumber: "Driving License Number",
      voteridnumber: "Voter ID Number",
      maturityamount: "Maturity Amount ",
      propertytax: "Property Tax",
      paidfinanceyear: "Financial Year",
      dateofclosure: "Date of Closure",
      cardtype: "Card Type",
      customcardtype: " Custom Card Type",
      donation:"Donation",

      custominsurance: "Custom Insurance Company",
      policynumber: "Policy Number",
      bankname: "Bank Name",
      cardnumber: "Card Number",
      customcategory: "Custom Category",
      limit: "Limit",
      insurancecompany: "Insurance Company",
      amountinvested: "Amount Invested",
      date: "Date",
      prinipalamount: "Outstanding Principal",
      nav: "NAV",
      occupation: "Occupation",
      validity: "Validity",
      trustorgoverment: "Trust/Goverment",
      rentamount: "Rent",

      occasionname: "Occasion Name",
      facilitiesavailed: "Facilities Availed",

      serviceprovider: "Service Provider",
      sumassured: "Sum Assured value greater than Premium",
      sumassuredauto: "Sum Assured value greater than Present Value",
      registrationnumber: "Registration Number",
      expirydate: "Expiry Date",
      accountnumber: "Account Number",
      advancereceived: "Advance Received",
      nomineeregistrationnumber: "Nominee Registration Number ",
      amountpaid:"Amount Paid",

      sanctionedamount:"Sanctioned Amount",

      ifsccode: "IFSC Code",
      accounttype: "Account Type",
      accountopendate: "Account Open date",
      debitcardnumber: "Debit Card Number",
      dateofentry: "Date of Entry",
      defaultdate: "Default Date",
      investmentcycle: "Investment Cycle",
      uannumber: "UAN Number",
      totalunits: "Total Units",
      isin: "ISIN",
      propertyname: "Property Name",
      nature: "Nature",
      expiry: "Expiry Date",
      customamc: "Custom AMC",
      premiumfrequency: "Mode",
      identifications: "Identifications",
      nameonlic: "Name On License",
      basicby: " Basic Pay (per month)",
      warrantyitems: "Warranty Excluusion",
      folionumber: "Folio Number",
      jewellerymetaltype: "Jewellery Metal Type",
      nominee: "Nominee",
      rent: "Rent",
      maturityvaluefd:"Maturity value should be more than Principal Amount",
      dearnessallowance: "Dearness Allowances (per month)",
      salareydate: "Salary Date",
      typeofmetal: "Type Of Jewellery",
      typeofbullion: "Type Of Bullion",
      nomineename: "Nominee Name",
      sumassuredtravel:"Sum Assured value should be more than Premium Paid",

      jewellerywight: "Jewellery Wight",
      localaddress: "Local Address",
      contractamount: "AMC",
      vehicleregnumber: "Vehicle Registration Number",
      expirydateyy: "Expiry Year",
      expirydatemm: "Expiry Month",
      value: "Value",
      maturityvalue: "Maturity value should be more than amount invested",
      permanentaddress: "Permanent Address",
      purchasedate: "Purchase Date",
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  fr: {
    messages: fr.messages,
    names: {
      email: 'E-mail',
      name: 'Nom',
      password: 'Password',
      state: 'Etat',
      status: 'Stas',
      profession: "Profession",
      dueon: "Dû le",

      lastName: 'nom de Famille',
      phonenumber: 'Numéro de Téléphone',
      gender: 'Genre',
      city: "ville",
      isin: "ISIN",
      nameofcouples: "Nom Du Couple",
      transationtype: "Transation Type",
      recurringdepamount: "Recurring Deposit Amount",

      policeholder: "Nom du titulaire de la police",
      jewellerymetaltype: "Types De Métaux de Bijoux",
      policynumber: "Numéro De Police",
      billnumber: "Numéro De Facture",
      purchasedate: "Date D'achat",
      contractamount: "AMC",
      vehicleregnumber: "Numéro d'immatriculation du véhicule",
      presentvalue: "Valeur Actuelle",
      helplinename: "Nom",
      dossage: "Dosage",
      organizationissued: "Organisation Délivrée",
      documentnumber: "Numéro De Document",
      advancepaid: "Avance Payée",
      locations: "Emplacements",
      nameofcertificate: "Nom Du Certificat",
      cardnumber: "Numéro De Carte",
      validity: "Validité",
      number: "Nombre",
      mode: "Modé",
      nature: "Nature",

      investmentcycle: "Cycle D'investissement",
      typeofjewellery: "Type De Bijoux",
      customamc: "AMC Personnalisé",
      policyno: "Numéro de police (~)",
      premiumfrequency: "Mode",
      referredby: "Référencé Par",
      localaddress: "Adresse Locale",
      identifications: "Identifications",
      takenon: "Pris sur",
      familymember: "Membre de la Famille",
      occasiondate: "Date D'occasion",
      imie: "Numéro IMEI",
      crnnumber: "Numéro CRN",

      partyhallcost: "Coût De La Salle des Fêtes",
      flouristcost: "Coût Fleuriste",
      tenthousecost: "Coût De La Maison de La Tente",
      securitydeposit: "Dépôt",
      passportnumber: "Numéro De Passeport",
      datefoexpiry: "Date D'expiration",
      qualififications: "Qualifications",

      languagepreference: 'Préférence de langue',
      maritalstatus: 'Etat civil',
      educationalqualification: 'Qualification pourl éducation',
      dateofbirth: 'Date de naissance',
      citizen: 'Citoyenne',
      schemename: "Nom du Schéma",
      custominsurance: "Compagnie D'assurance Personnalisée",
      sumassured: "Valeur de la somme assurée supérieure au montant de la prime",
      valeu: "Valeur",
      dateofjoin: "Date D'adhésion",
      make: "Faire",
      budget: "Budget",
      nameonlic: "Nom sur la licence",
      consumernumber: "Numéro De Consommateur",
      isinname: "ISIN Nom",

      planningfunftion: "Planifier une fonction",
      cateringcost: "Coût De La Restauration",
      documentsname: "Nom Du Document",
      voteridnumber: "Numéro d'identification De L'électeur",
      trustorgoverment: "Confiance/Gouvernement",
      rentamount: "Loyer",
      typeofloan:"Type de prêt",

      gift: "Cadeau",
      select: "Sélectionner",
      dateofregister: "Date D'enregistrement",
      hospitalname: "Nom de l'hôpital/de la clinique",
      prescription: "Ordonnance",
      reports: "Rapports",
      travelingdate: "Date De Voyage",
      destination: "Destination",
      serviceprovider: "Fournisseur De Services",
      partyarea: "Zone De Fête",
      capacity: "Capacité",
      lpgid: "ID GPL",
      voteridname: "Nom De L'électeur",
      donation:"Don",
      tutionfees:"Frais de scolarité",
      premium: "Prime",

      nomineename: "Uités Totales",
      nav: "Navigation",
      folionumber: "Numéro de Folio",
      jewellerywight: "Poids Des Bijoux",
      premiumamount: "Montant De La Prime",
      registrationnumber: "Numéro D'enregistrement",
      serialnumber: "Numéro De Série",
      exclusions: "Exclusions",
      warrantyexpirydate: "date d'expiration de La Garantie",
      nextvisitdate: "Date De la Prochaine Visite",
      doctorname: "Nom Du Médecin",
      modeoftravel: "Mode De Voyage",
      numpassengers: "Nombre De Passagers",
      dlnumber: "Numéro De Permis De Conduire",
      propertyname: "Nom De la Propriété",
      propertytax: "Taxe De Propriété",
      yearasfinance:"Année (Selon l'année financière)",

      referedby: 'Référencé par',
      currency: 'Monnaie',
      relations: 'Rapports',
      maturityvalue: "Valeur à l'échéance supérieure au Montant Investi",
      customrelations: 'relations personnalisées',
      bloodgrouptype: 'Type de Groupe Sanguin',
      propertytype: 'propertytype',
      oenedby: "Appartenant à",
      enddate: "Date De Fin",
      searchifsc: "Rechercher IFSC",
      paidfinanceyear: "Année financière",

      nomineename: "Nom du nominé",
      nomineename: "Uités Totales",
      bankpostname: "Nom de la Banque/Bureau de poste",
      childname: "Nom D'enfant",
      amc: "AMC",
    amountpaid:"Le montant payé",
    surendervalue:"Valeur de rachat",
    nextduedate:"prochaine date d'échéance",
    coverage:"Couverture (individuelle ou familiale)",

      yearmodel: "Année (Modèle)",
      renewaldate: "Date De Renouvellement",
      pancardnumber: "Numéro De Carte Panoramique",
      customercode: "Code client",
      examcycle: "Cycle D'examen",
      fees: "Frais",
      charges: "Des charges (Mensuel)",
      permanentaddress: "Adresse Permanente",
      warrantyupto: "Garantie Jusqu'à",
      enginenumber: "Numéro De Moteur",
      chasisnumber: "Numéro De Châssis",
      custommake: "Faire Sur Mesure",
      dob: "Date de naissance",
      departing: "Départ",
      amount: "Aontante",
      nameofpancard: "Nom de Pancard",
      rentmonthly: "Loyer (mensuel)",
      gradeclass: "Classe/Niveau",
      rankgrade: "Rang",
      occupation: "Profession",
      warrantyitems: "Exclusions de Garantie",
      itemdescription: "Catégorie d'article",

      areainsequarefeet: "areainsequarefeet",
      address: "Adresse",
      pincode: "Code PIN",
      amountinvested: "Montant Investi",
      propertyid: "ID de propriété",
      remarks: "Remarques",
      ownername: "Nom",
      holdername: "Nom ",
      company: "Entreprise",
      salareydate: "Date de Salaire",
      vaccinationtype: "Type De Vaccination",
      passportname: " Nom Du Passeport",
      maximummark: "Notes Maximales",
      description: "Description",
      commencdate:"Date de début",

      renewaldate: "Date De Renouvellement",
      contractexpirydate: "Date D'expiration Du Contrat",
      aadharnum: "Auméro De Carte Aadhar",
      markobtain: "Notes Obtenues",
      validupto: "Valable jusqu'au",
      lastcloprice: "Dernier Cours De Clôture",
      nomineeregistrationnumber: "Numéro D'inscription Du Candidat",
      maturityamount: "Montant à l'échéance Plus que le montant RD",
      rent: "Louer",
      weight: "Lester",
      recurringdepamount: "Montant du Dépôt Récurrent",
      amountresived:"Montant reçu de (nom)",
      typename: "Nom de membre",

      insurancecompany: "Compagnie D'assurance",
      openingbalance: "Solde D'ouverture",
      systemdate: "Date du Système",
      fileinput: "Entrée de fichier",
      loangivento: "Montant donné à (nom)",
      outstantamount: "Encours",
      returndate: "Date de retour prévue",
      category: "catégorie ",
      totalunits: "Tunités totales",
      model: "Modèle",
      nextservicedate: "Prochaine Date De Service",
      visitdate: "Date De Visite",
      occasionname: "Nom De L'occasion",
      marriagecertificate: "Certificat De Mariage",
      lockernum: "Numéro de Casier",
      transationtype: "Type De Transaction",
      paytype: "Type De Paie",
      nscnumber: "NSC Nombre",
      "prinipalamount": "Principal exceptionnel",
      honame:"Nom du titulaire",

      accountholdername: "Nom",
      loanavailedfrom: "Prêt Obtenu Auprès De",
      loanid: "Numéro de compte de prêt",
      loanamount: "Montant Du Prêt",
      emi: "EMI",
      typeofbullion: "Types De Lingots",
      value: "Valeur",
      placeofbirth: "Lieu De Naissance",
      residentialstatus: "Statut Résidentiel",
      date: "Date",
      expirydate: "Date D'expiration",
      advancereceived: "Avance reçue",
      houseaddress: "Loger",

      startdate: "Date de Début",
      rateofintrest: "Le taux d'intérêt doit être compris entre 1 et 100",
      emidate: "La date EMI doit être comprise entre 1 et 31 de chaque mois",
      dateofclosure: "Date De Fermeture",
      cardtype: "Type De Carte",
      bankname: "Nom de Banque",
      cardnumber: "Numéro de Carte",
      customcategory: "Catégorie Personnalisée",
      limit: "Limite",
      subject: "Sujette",
      percentageheld: "Pourcentage Détenu Inférieur à 100(%)",
      facilitiesavailed: "Installations Disponibles",
      rdnumber: "Numéro DR",
      lender: "Prêteuse",
      occupanttype:"Type d'occupant",
      typedate: "Date de l'occasion",

      membershipnum: "Numéro de Membre",
      organization: "Organisation",
      profession: "Profession",
      accountnumber: "Numéro De Compte",
      ifsccode: "Code IFSC",
      accounttype: "Type de compte",
      accountopendate: "Numéro De Carte De Débit",
      debitcardnumber: "Debit card number",
      dateofentry: "Date of Entry",
      defaultdate: "Date Par Défaut",
      uannumber: "Nombre UAN",
    sanctionedamount:"Montant sanctionné",

      basicby: "Salaire de base (par mois)",
      dearnessallowance: "Allocations de cherté (par mois)",
      typeofmetal: "Type De Bijoux",

    },
    fields: {
      password: {
        min: '{_field_} is too shortfsdsdd, you want to get hacked?',
      },
    },
  },
  de: {
    messages: de.messages,
    names: {
      email: 'Email',
      password: 'Password',
      state: 'State',
    },
    fields: {
      password: {
        min: '{_field_} is too shortfsdsdd, you want to get hacked?',
      },
    },
  },
  ar: {
    messages: ar.messages,
    names: {
      email: 'البريد الإلكتروني',
      password: 'كلمة السر',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
})
