import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/documents', {
            params: queryParams
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/documents/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`documents/download/${id}`,

            { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/documents', {
            status: 1,
            data: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/documents/${userData.id}`, {
            status: 1,
            name: userData.name,
            data: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`filedocument/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDataCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/all/personal/documentcategory')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
